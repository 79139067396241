<template>
  <v-card>
    <v-card-title>
      {{ moduleTitle }} ({{ totalRecords }})
      <v-spacer></v-spacer>
    </v-card-title>
    <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
    >
    </v-skeleton-loader>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-btn color="#00004d" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="updateFiles" v-if="items.length > 0 && !showSortOverLay">
                Update All
                <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn color="primary" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="refreshData" v-if="items.length > 0 && !showSortOverLay">
                Refresh
                <v-icon dark x-small class="ml-1">fas fa-redo</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-btn color="#00004d" dark
                 class="ml-auto ma-3"
                 style="width:100%"
                 @click="updateFiles" v-if="itemSelectedIndexes.length > 0">
            Update All
            <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
          </v-btn>
          <v-checkbox color="#00004d"
                      v-if="items.length > 0"
                      :value="showSortOverLay"
                      @change="showSortChange($event)"
                      label="Change Sort Order By Clicking"></v-checkbox>
        </v-col>
        <v-col cols="4" v-for="(item, index) in items" :key="index"
               class="gallery-files-col">
          <div class="media-overlay"
               :ref="'media_' + index"
               v-if="showSortOverLay"
               @click="setSortOrder(index, item)"
               v-html="item.sort_order"
          >
          </div>
          <v-btn color="red" x-small dark @click="deleteFile(item)" class="delete_gallery_file_btn">
            <v-icon dark x-small>fas fa-times</v-icon>
          </v-btn>

          <iframe
              v-if="
              item.extension === '.doc' ||
              item.extension === '.docm' ||
              item.extension === '.docx' ||
              item.extension === '.txt' ||
              item.extension === '.csv' ||
              item.extension === '.xls' ||
              item.extension === '.xlsx' ||
              item.extension === '.ppt' ||
              item.extension === '.pptx'
              "
              :src="'https://docs.google.com/gview?url='+appUrl + item.url+'&embedded=true'"></iframe>

          <object v-else :data="appUrl + item.url"
                  style="max-width:100%; max-height:300px;"></object>
          <v-form class="mt-10">
            <v-text-field
                disabled
                outlined
                dense
                :value="item.title + item.extension"
                label="File Name"
            ></v-text-field>
            <v-text-field
                outlined
                dense
                :counter="100"
                maxLength="100"
                v-model="item.title"
                label="Edit File Name"
            ></v-text-field>
            <v-text-field
                outlined
                dense
                :counter="10"
                maxLength="10"
                @keypress="isNumber($event)"
                v-model="item.sort_order"
                label="Sort Order"
            ></v-text-field>
<!--            <v-select-->
<!--                outlined-->
<!--                dense-->
<!--                v-model="item.module_purpose"-->
<!--                v-if="moduleTitle === 'Gallery'"-->
<!--                clearable-->
<!--                label="Select File Type"-->
<!--                :items="galleyPurposeFilters"-->
<!--                item-text="title"-->
<!--                item-value="value"-->
<!--            ></v-select>-->
<!--            <v-text-field outlined-->
<!--                          dense-->
<!--                          :counter="100" v-model="item.tags"-->
<!--                          label="Tags"-->
<!--            ></v-text-field>-->
<!--            <v-textarea outlined-->
<!--                        dense-->
<!--                        :counter="300" v-model="item.description"-->
<!--                        label="Description"-->
<!--            ></v-textarea>-->
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalRecords"
        :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true
                  }"
        :loading="loading"
        v-show="!isLoaded"
        mobile-breakpoint="800"
        class="elevation-0 custom-datatable">
    </v-data-table>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import {UPLOADED_FILES} from "@/core/services/store/uploads.module";
// import {bus} from "@/main";

export default {
  name: 'module-uploaded-files',
  props: [
    "moduleItem",
    "moduleTitle",
    "moduleOf",
    "moduleKey"
  ],
  data() {
    return {
      appUrl: this.$store.state.appUrl + '/',
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      headers: [
        {
          text: 'Name',
          value: 'title',
          data: 'title',
          name: 'title',
          filterable: true,
          sortable: true
        },
        // {
        //   text: 'Tags',
        //   value: 'tags',
        //   data: 'tags',
        //   name: 'tags',
        //   filterable: true,
        //   sortable: false
        // },
        // {
        //   text: 'Description',
        //   value: 'description',
        //   data: 'description',
        //   name: 'description',
        //   filterable: true,
        //   sortable: false
        // },
        {text: "Sort Order", value: "sort_order", name: "sort_order", filterable: true, sortable: true},
        {text: "Created", value: "created_at", name: "created_at", filterable: true, sortable: true},
        {text: 'Action', value: 'actions', data: null, name: null, filterable: false, sortable: false},
      ],
      items: [],
      itemSelectedIndexes: [],
      showSortOverLay: false,
      sortInt: 1
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].data +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "asc" : "desc");
        this.$store.dispatch(UPLOADED_FILES, {
          modelId: this.moduleItem.id,
          model: this.moduleOf,
          modelKey: this.moduleKey,
          moduleTitle: this.moduleTitle,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    deleteFile(file) {
      let that = this;
      let path = file.url;
      this.$store
          .dispatch("delete_uploaded_file", {
            file: path,
            model: this.moduleOf,
            delete_model: true,
            type: "old"
          })
          .then(() => {
            that.loadData().then(response => {
              this.items = response.items;
              this.totalRecords = response.total;
            });
          });
    },
    updateFiles() {
      let that = this;
      this.$store
          .dispatch("update_files", {
            files: this.items,
            model: this.moduleOf,
          })
          .then(() => {
            that.loadData().then(response => {
              this.items = response.items;
              this.totalRecords = response.total;
              this.showSortOverLay = false;
              this.sortInt = 1;
              this.itemSelectedIndexes = [];
            });
          });
    },
    showSortChange($event) {
      this.showSortOverLay = !!$event;
      this.sortInt = 1;
      this.itemSelectedIndexes = [];
      if ($event) {
        this.items.findIndex(element => {
          element.sort_order = null;
        });
      } else {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      }
    },
    setSortOrder(index, item) {
      let itemCurrentIndex = item.sort_order;
      if (this.itemSelectedIndexes.length > 0) {
        let fIndex = this.itemSelectedIndexes.find(({sort}) => sort === itemCurrentIndex);
        if (fIndex) {
          let fItem = this.items.find(({id}) => id === item.id);
          if (fItem) {
            fItem.sort_order = null;
            this.itemSelectedIndexes.splice(this.itemSelectedIndexes.indexOf(fIndex), 1)
            this.sortInt--
            this.itemSelectedIndexes.findIndex(element => {
              if (element.sort > itemCurrentIndex) {
                  let nItem = this.items.find(({id}) => id === element.item);
                  if (nItem) {
                    nItem.sort_order = nItem.sort_order - 1;
                    element.sort = element.sort - 1
                  }
              }
            });
          }
        } else {
          let fItem = this.items.find(({id}) => id === item.id);
          if (fItem) {
            fItem.sort_order = this.sortInt++;
            this.itemSelectedIndexes.push({item: fItem.id, sort: fItem.sort_order})
          }
        }
      } else {
        let fItem = this.items.find(({id}) => id === item.id);
        if (fItem) {
          fItem.sort_order = this.sortInt++;
          this.itemSelectedIndexes.push({item: fItem.id, sort: fItem.sort_order})
        }
      }
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.uploads.errors
    })
  }
};
</script>